export default class Multiplex {
  public id
  public name
  public subtitle
  public startDate
  public logoUrl
  public participatingShows
  public pledgesCurrency
  public totalPledgesAmount
  public averagePledgesAmount
  public allPledgesCount;
  public totalUsersCount;
  public globalMultiplexGoal;
  public currentDashboardId;
  public backgroundColorDark;
  public backgroundColorLight;
  public fontColor;
  public fontColorHighlight;
  public isArchived;

  constructor(
    id: string,
    name: string,
    subtitle: string = "",
    startDate: Date = new Date(),
    logoUrl: string = "",
    participatingShows: string[] = [],
    pledgesCurrency: string = "EUR",
    totalPledgesAmount: number = 0,
    averagePledgesAmount: number = 0,
    allPledgesCount: number = 0,
    totalUsersCount: number = 0,
    globalMultiplexGoal: number = 0,
    currentDashboardId: string = "",
    backgroundColorDark: string = "#00112D",
    backgroundColorLight: string = "#0146FC",
    fontColor: string = "#fff",
    fontColorHighlight: string = "#FF4700",
    isArchived: boolean = false,
  ) {
    this.id = id
    this.name = name
    this.subtitle = subtitle
    this.startDate = startDate
    this.logoUrl = logoUrl
    this.participatingShows = participatingShows
    this.pledgesCurrency = pledgesCurrency
    this.totalPledgesAmount = totalPledgesAmount
    this.averagePledgesAmount = averagePledgesAmount
    this.allPledgesCount = allPledgesCount
    this.totalUsersCount = totalUsersCount
    this.globalMultiplexGoal = globalMultiplexGoal
    this.currentDashboardId = currentDashboardId
    this.backgroundColorDark = backgroundColorDark
    this.backgroundColorLight = backgroundColorLight
    this.fontColor = fontColor
    this.fontColorHighlight = fontColorHighlight
    this.isArchived = isArchived
  }
}